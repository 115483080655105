(function (window, document, $) {

    $.fn.myForm = function (options) {

        var form = this[0];

        options = $.extend({
            button: 'submit_button',
            recaptcha: null,
            url: form.action,
            method: 'POST',
            success_div: 'success',
            warning_div: 'warning',
            success: function () {
                button.classList.add('d-none');
                success_div.classList.remove('d-none');
                form.reset();
            },
            error: function () {
                button.disabled = false;
                warning_div.classList.remove('d-none');
            }
        }, (options || {}));

        var success_div = document.getElementById(options.success_div);
        var warning_div = document.getElementById(options.warning_div);
        var button 		= document.getElementById(options.button);

        function submit (values) {
            $.ajax(options.url, {
                type: options.method,
                data: values,
                headers: { 'Accept': 'application/json' },
                success: options.success,
                error: options.error
            });
        }

        $(this).parsley().on('form:submit', function () {

            button.disabled = true;
            warning_div.classList.add('d-none');

            var values = {};

            for (var i = 0; i < form.elements.length; i++) {
                var e = form.elements[i];
                switch (e.type) {
                    case 'submit':
                        break;
                    case 'checkbox':
                        if (e.checked) {
                            values[e.name] = 1;
                        }
                        break;
                    default:
                        values[e.name] = e.value;
                        break;
                }
            }

            if (options.recaptcha !== null) {
                grecaptcha.ready(function() {
                    grecaptcha.execute(options.recaptcha).then(function(token) {
                        values['g-recaptcha-response'] = token;
                        submit(values);
                    });
                });
            } else {
                submit(values);
            }

            return false;

        });
    }

})(window, document, jQuery)
